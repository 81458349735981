// Legacy - delete
section.footnotes + footer.footer {
  @include desktop-1920(
    (
      background-color: $white,
      padding-top: 50px
    )
  );

  @include phone(
    (
      padding: 10px 50px 45px 50px,
      align-items: flex-start
    )
  );
}

footer.footer {
  @include desktop-1920(
    (
      background-color: $white,
      padding: 95px 160px 110px 160px
    )
  );

  @include phone(
    (
      padding: 50px 50px 45px 50px,
      align-items: flex-start
    )
  );

  .footer--logo {
    @include desktop-1920(
      (
        margin-bottom: 30px,
        padding: 0 0 0 80px
      )
    );

    @include phone(
      (
        margin-bottom: 30px,
        padding: 0
      )
    );

    img,
    svg.img {
      @include desktop-1920(
        (
          height: 42px,
          width: 340px
        )
      );

      @include phone(
        (
          height: 23px,
          width: 178px
        )
      );
    }
  }

  .footer--contents {
    @include desktop-1920(
      (
        display: flex,
        align-items: flex-start,
        width: 100%,
        margin-bottom: 40px,
        padding: 0 50px 0 80px
      )
    );

    @include phone(
      (
        flex-direction: column,
        margin-bottom: 25px
      )
    );

    ul.footer--menu {
      @include desktop-1920(
        (
          display: flex,
          list-style: none,
          padding: 0 0,
          margin: 0 70px 0 0,
          order: 1
        )
      );

      @include phone(
        (
          display: block,
          width: 100%,
          overflow: hidden,
          margin: 0 0 45px 0,
          padding: 0,
          order: 1
        )
      );

      > li {
        @include desktop-1920(
          (
            margin-right: 30px
          )
        );

        @include phone(
          (
            width: 100%,
            margin: 0 0 10px 0,
            padding: 0,
            float: left
          )
        );

        &:last-child {
          margin-right: 0;
        }

        > a {
          @include regular-font;
          @include desktop-1920(
            (
              display: block,
              text-decoration: none,
              margin-bottom: 10px,
              font-size: 17px,
              color: $black-light,
              letter-spacing: 1.1px,
              line-height: 25px
            )
          );

          @include phone(
            (
              font-size: 13px,
              line-height: 20px,
              margin-bottom: 10px,
              letter-spacing: 0.93px
            )
          );

          &:hover {
            color: $nm-blue;
            text-decoration: underline;
          }

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }

    .footer--social {
      @include desktop-1920(
        (
          flex: 1,
          margin-right: 50px,
          order: 2
        )
      );

      @include phone(
        (
          margin: 0,
          order: 3
        )
      );

      .footer--social-eyebrow {
        @include regular-font;
        @include desktop-1920(
          (
            display: block,
            color: $black-light,
            line-height: 20px,
            font-size: 17px,
            letter-spacing: 1.21px,
            margin: 0 0 10px 0
          )
        );

        @include phone(
          (
            display: none
          )
        );
      }

      > a {
        @include desktop-1920(
          (
            text-decoration: none,
            margin-right: 10px
          )
        );

        @include phone(
          (
            margin-right: 10px
          )
        );

        > img,
        > svg.img {
          @include desktop-1920(
            (
              height: 30px,
              width: 30px
            )
          );

          @include phone(
            (
              height: 30px,
              width: 30px
            )
          );
        }
      }
    }

    .footer--stats {
      @include desktop-1920(
        (
          order: 3
        )
      );

      @include phone(
        (
          margin-bottom: 0,
          padding: 0 0 45px 0,
          order: 2,
          width: auto,
          max-width: 100%
        )
      );

      > label {
        @include light-font;
        @include desktop-1920(
          (
            color: $black-light,
            display: block,
            font-size: 25px,
            letter-spacing: 1.79px,
            line-height: 26px,
            margin-bottom: 13px
          )
        );

        @include phone(
          (
            font-size: 18px,
            line-height: 25px,
            letter-spacing: 1.29px,
            margin: 0 0 30px 0,
            padding: 0
          )
        );
      }

      > a {
        @include semibold-font;
        @include desktop-1920(
          (
            color: $nm-blue,
            cursor: pointer,
            text-decoration: none,
            text-transform: uppercase,
            font-size: 12px,
            letter-spacing: 3px,
            line-height: 18px
          )
        );

        @include phone(
          (
            position: relative,
            font-size: 12px,
            letter-spacing: 2px,
            line-height: 18px
          )
        );

        > img,
        > svg.img {
          @include desktop-1920(
            (
              vertical-align: middle,
              height: 10px,
              width: 6px
            )
          );

          @include phone(
            (
              height: 10px,
              width: 6px
            )
          );
        }

        &:hover {
          color: #397bb1;
          text-decoration: underline;
        }
      }
    }
  }

  #footer--disclosures-collapse-toggle {
    @include desktop-1920(
      (
        position: absolute,
        left: -9999px
      )
    );

    &:checked ~ .footer--show-when-expanded {
      display: block;
    }

    &:checked ~ .footer--show-when-collapsed {
      display: none;
    }
  }

  .footer--show-when-expanded {
    display: none;
  }

  .footer--show-when-collapsed {
    display: block;
  }

  .footer--toggle-label {
    @include regular-font;
    @include desktop-1920(
      (
        width: 100%,
        text-align: center,
        color: $nm-blue,
        margin: 0 0 15px 0,
        cursor: pointer,
        font-size: 14px,
        letter-spacing: 1px
      )
    );

    @include phone(
      (
        font-size: 10px,
        margin: 10px 0 10px 0
      )
    );

    &:hover {
      @include desktop-1920(
        (
          color: $yellow-darker
        )
      );
    }
  }

  .footer--disclosures {
    @include desktop-1920(
      (
        padding: 0 50px 0 80px,
        margin: 0 0 60px 0,
        border: 1px solid $silver,
        border-width: 0 0 1px 0
      )
    );

    @include phone(
      (
        padding: 0,
        margin: 0 0 45px 0,
        border-width: 0 0 1px 0
      )
    );

    p {
      @include regular-font;
      @include desktop-1920(
        (
          color: #666666,
          font-size: 12px,
          letter-spacing: 1px,
          margin: 0 0 10px 0,
          line-height: 16px
        )
      );

      @include phone(
        (
          font-size: 9px,
          letter-spacing: 1px,
          margin: 0 0 7px 0,
          line-height: 13px
        )
      );

      &:last-child {
        @include desktop-1920(
          (
            margin-bottom: 20px
          )
        );
      }
    }
  }

  .footer--legal {
    p {
      @include regular-font;
      @include desktop-1920(
        (
          color: #666666,
          font-size: 11px,
          padding: 0 50px 0 80px,
          margin: 0 0 20px 0,
          line-height: 22px
        )
      );

      @include phone(
        (
          font-size: 11px,
          margin: 0 0 14px 0,
          line-height: 22px,
          letter-spacing: 0
        )
      );

      &.footer--finra {
        a {
          @include desktop-1920(
            (
              vertical-align: middle,
              margin-left: 5px
            )
          );

          @include phone(
            (
              margin-left: 0,
              display: block
            )
          );
        }

        img,
        svg.img {
          @include desktop-1920(
            (
              height: 15px,
              width: 15px,
              vertical-align: baseline
            )
          );

          @include phone(
            (
              height: 10px,
              width: 10px,
              vertical-align: middle
            )
          );
        }
      }
    }
  }
}
