// Legacy - delete
.row {
  @include desktop-1920(
    (
      display: flex,
      flex-direction: row,
      justify-content: flex-start,
      align-items: flex-start,
      flex-wrap: wrap,
      width: 100%,
      text-align: left
    )
  );
}
