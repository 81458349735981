// Legacy - delete
@mixin no-tap {
  outline: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

@mixin touch-overflow {
  -webkit-overflow-scrolling: touch;
  overflow-x: hidden;
  overflow-y: auto;
}
