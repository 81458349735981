// Legacy - delete
* {
  max-width: 100%;
  box-sizing: border-box;
  font-family: $font-family;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &.is-only-on-desktop {
    display: initial;
  }

  &.is-only-on-phone {
    display: none;
  }

  @include phone {
    @include no-tap;

    &.is-only-on-desktop {
      display: none;
    }

    &.is-only-on-phone {
      display: initial;
    }
  }
}

svg {
  &.is-correlated-svgs {
    position: absolute;
    overflow: hidden;
    width: 0;
    height: 0;
  }
}
