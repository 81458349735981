// Legacy - delete
.column {
  @include desktop-1920(
    (
      display: flex,
      flex-direction: column,
      padding: 0 25px,
      width: 100%
    )
  );

  @include phone(
    (
      padding: 0
    )
  );

  &.is-aligned-center {
    @include desktop-1920(
      (
        text-align: center
      )
    );

    @include phone(
      (
        text-align: left
      )
    );
  }

  &.is-aligned-right {
    @include desktop-1920(
      (
        text-align: right
      )
    );

    @include phone(
      (
        text-align: left
      )
    );
  }

  &.items-align-left {
    @include desktop-1920(
      (
        align-items: flex-start
      )
    );
  }

  &.items-align-center {
    @include desktop-1920(
      (
        align-items: center
      )
    );
  }

  &.items-align-right {
    @include desktop-1920(
      (
        align-items: flex-end
      )
    );
  }

  &.is-centered-vertically {
    align-self: center;
  }

  &.without-gutter {
    @include desktop-1920(
      (
        padding: 0
      )
    );
  }

  &.with-large-gutter {
    @include desktop-1920(
      (
        padding: 0 55px
      )
    );

    @include phone(
      (
        padding: 0
      )
    );
  }

  &.with-xlarge-gutter {
    @include desktop-1920(
      (
        padding: 0 75px
      )
    );

    @include phone(
      (
        padding: 0
      )
    );
  }

  &.with-xxlarge-gutter {
    @include desktop-1920(
      (
        padding: 0 100px
      )
    );

    @include phone(
      (
        padding: 0
      )
    );
  }

  &:first-child {
    @include desktop-1920(
      (
        padding-left: 0
      )
    );
  }

  &:last-child {
    @include desktop-1920(
      (
        padding-right: 0
      )
    );
  }

  &.is-half {
    @include desktop-1920(
      (
        width: 50%
      )
    );

    @include phone(
      (
        width: 100%
      )
    );
  }

  &.is-one-third,
  &.is-third {
    @include desktop-1920(
      (
        width: (
          1/3
        ) * 100%
      )
    );

    @include phone(
      (
        width: 100%
      )
    );
  }

  &.is-two-thirds {
    @include desktop-1920(
      (
        width: (
          2/3
        ) * 100%
      )
    );

    @include phone(
      (
        width: 100%
      )
    );
  }

  &.is-one-fourth,
  &.is-fourth {
    @include desktop-1920(
      (
        width: (
          1/4
        ) * 100%
      )
    );

    @include phone(
      (
        width: 50%
      )
    );
  }

  &.is-three-fourths {
    @include desktop-1920(
      (
        width: (
          3/4
        ) * 100%
      )
    );

    @include phone(
      (
        width: 100%
      )
    );
  }

  &.with-left-border {
    @include desktop-1920(
      (
        border-left: 1px solid #aaaaaa
      )
    );

    @include phone(
      (
        border-left: none
      )
    );
  }

  &.with-right-border {
    @include desktop-1920(
      (
        border-right: 1px solid $nm-yellow
      )
    );

    @include phone(
      (
        border-right-width: 0
      )
    );
  }
}
