// Legacy - delete
.general-thank-you {
  @include desktop-1920(
    (
      display: flex,
      flex-direction: column,
      align-items: center,
      justify-content: center,
      align-self: center,
      text-align: center,
      width: 400px,
      margin: 0 auto,
      padding: 50px 0,
      min-height: 100%
    )
  );

  @include phone(
    (
      width: 100%,
      padding: 0 15px 50px 15px
    )
  );

  &.is-wide {
    @include desktop-1920(
      (
        width: 600px
      )
    );

    @include phone(
      (
        width: 100%
      )
    );
  }

  img,
  svg.img {
    @include desktop-1920(
      (
        height: 182px,
        width: 182px,
        margin: 0 0 15px 0
      )
    );

    @include phone(
      (
        height: 182px,
        width: 182px,
        margin: 0 0 20px 0
      )
    );
  }

  h3 {
    @include regular-font;
    @include desktop-1920(
      (
        font-size: 35px,
        color: $grey-dark,
        letter-spacing: 2.36px,
        line-height: 53px,
        margin: 0 0 10px 0,
        text-align: center
      )
    );

    @include phone(
      (
        font-size: 24px,
        color: $grey-dark,
        letter-spacing: 3px,
        line-height: 28px,
        margin: 0 0 20px 0
      )
    ) {
      @include regular-font;
    }
  }

  h4 {
    @include regular-font;
    @include desktop-1920(
      (
        font-size: 20px,
        color: $grey-dark,
        letter-spacing: 1.71px,
        line-height: 29px,
        margin: 0,
        text-align: center
      )
    );

    @include phone(
      (
        font-size: 15px,
        color: $grey-dark,
        letter-spacing: 1px,
        line-height: 25px
      )
    ) {
      @include regular-font;
    }
  }
}
