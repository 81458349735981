// Legacy - delete
.nmx-button {
  // namespaced everything in a nmx-button--legacy so these won't apply to other nmx-button styles.
  &.nmx-button--legacy {
    border-width: 0;
    @extend %nmx-button;

    &.nmx-button--secondary {
      @extend %nmx-button--secondary;
    }

    &.nmx-button--small {
      @extend %nmx-button--small;
    }
  }
}

#nmx-registration-button {
  @include desktop-1920(
    (
      width: 316px,
      height: 72px,
      margin: 18px 0 0 0
    )
  );

  @include phone(
    (
      height: 44px,
      width: 198px,
      margin-top: 3px
    )
  );
}
