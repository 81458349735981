p {
  @include light-font;
  @include desktop-1920(
    (
      font-size: 18px,
      line-height: 24px,
      letter-spacing: 1px
    )
  );

  @include phone(
    (
      font-size: 16px,
      line-height: 32px,
      letter-spacing: 0.8px
    )
  );
}
