// Legacy - delete
@keyframes reveal-animation {
  0% {
    right: 10000px;
    left: -10000px;
  }

  .1% {
    right: 0;
    left: 0;
  }

  100% {
    right: 0;
    left: 0;
  }
}

@mixin reveal($duration: $short) {
  animation-duration: $duration;
  animation-fill-mode: forwards;
  animation-name: reveal-animation;
}

@keyframes alt-reveal-animation {
  0% {
    right: 10000px;
    left: -10000px;
  }

  .1% {
    right: inherit;
    left: inherit;
  }

  100% {
    right: inherit;
    left: inherit;
  }
}

@mixin alt-reveal($duration: $short) {
  animation-duration: $duration;
  animation-fill-mode: forwards;
  animation-name: alt-reveal-animation;
}

@keyframes conceal-animation {
  0% {
    right: 0;
    left: 0;
  }

  99.9% {
    right: 0;
    left: 0;
  }

  100% {
    right: 10000px;
    left: -10000px;
  }
}

@mixin conceal($duration: $short) {
  animation-duration: $duration;
  animation-fill-mode: forwards;
  animation-name: conceal-animation;
}

@keyframes alt-conceal-animation {
  0% {
    right: inherit;
    left: inherit;
  }

  99.9% {
    right: inherit;
    left: inherit;
  }

  100% {
    right: 10000px;
    left: -10000px;
  }
}

@mixin alt-conceal($duration: $short) {
  animation-duration: $duration;
  animation-fill-mode: forwards;
  animation-name: alt-conceal-animation;
}

@mixin remove-animation {
  animation-duration: 0s;
  animation-fill-mode: none;
  animation-name: none;
}

@keyframes shake {
  0% {
    transform: rotate(0deg);
  }

  10%,
  30%,
  50%,
  70%,
  90% {
    transform: translate(-5px, 0) rotate(-5deg);
  }

  20%,
  40%,
  60%,
  80% {
    transform: translate(5px, 0) rotate(5deg);
  }

  100% {
    transform: rotate(0deg);
  }
}
