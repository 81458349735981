// Legacy - delete

@import './legacy/mixins/cross-browser-mixins.scss';

div.general-form,
form.general-form {
  @include desktop-1920(
    (
      display: flex,
      flex-direction: column,
      justify-content: flex-start,
      align-items: flex-start,
      padding: 0,
      margin: 0
    )
  );

  @include phone(
    (
      margin: 0 -5px
    )
  );

  .general-form--input {
    @include light-font;
    @include desktop-1920(
      (
        position: relative,
        display: flex,
        flex-direction: row,
        align-items: center,
        justify-content: flex-start,
        flex-wrap: wrap,
        margin: 0 0 30px 0,
        font-size: 22px,
        color: #2a2a2a,
        letter-spacing: 1.1px,
        line-height: 30px
      )
    );

    @include phone(
      (
        display: block,
        font-size: 15px,
        color: $grey-dark,
        letter-spacing: 1px,
        line-height: 20px,
        margin: 0 0 25px 0
      )
    ) {
      @include regular-font;
    }

    sup {
      @include desktop-1920(
        (
          color: $nm-blue,
          font-size: 22px,
          top: 0
        )
      );

      @include phone(
        (
          font-size: 15px,
          margin-left: 3px
        )
      );
    }

    label {
      @include desktop-1920(
        (
          position: relative,
          display: block,
          width: 100%,
          margin-left: 10px,
          margin-right: 5px
        )
      );

      @include phone(
        (
          margin-left: 0,
          margin-right: 0,
          padding: 0 5px
        )
      );

      &:first-child {
        @include desktop-1920(
          (
            margin-left: 0
          )
        );
      }

      &.is-inline {
        @include desktop-1920(
          (
            display: inline-flex,
            align-items: center,
            justify-content: center,
            margin: 0 0 0 20px,
            width: auto
          )
        );

        @include phone(
          (
            display: block,
            width: 100%,
            margin: 0,
            padding: 5px 0
          )
        );

        input {
          @include desktop-1920(
            (
              margin: 0 0 4px 8px,
              transform: none
            )
          );

          @include phone(
            (
              margin: 0 0 0 6px,
              transform: scale(1.5, 1.5)
            )
          );
        }

        input[type='radio'],
        input[type='checkbox'] {
          @include is-FF {
            @include desktop-1920(
              (
                transform: scale(1.3, 1.3)
              )
            );
            @include phone(
              (
                margin: 0 0 0 6px,
                transform: scale(1.5, 1.5)
              )
            );
          }
        }
      }
    }

    input[type='text'],
    input[type='tel'],
    input[type='date'],
    input[type='number'],
    select,
    textarea {
      @include desktop-1920(
        (
          box-shadow: inset 0 1px 2px rgba($black, 0.3),
          width: 100%,
          border: 1px solid #707070,
          border-radius: 2px,
          margin: 5px 0 0 0,
          outline: none,
          padding: 0 15px,
          font-size: 22px
        )
      );

      @include phone(
        (
          box-shadow: none,
          border-width: 1px,
          padding: 0 10px,
          font-size: 14px,
          margin: 2px 0 0 0
        )
      );

      &:focus {
        @include desktop-1920(
          (
            box-shadow: #{'0px 0 3px ' + rgba($blue, 0.25) +
              ', inset 0 1px 2px ' + rgba($black, 0.3)},
            border-color: $blue
          )
        );

        @include phone(
          (
            box-shadow: none,
            border-color: $blue
          )
        );
      }

      &::placeholder {
        @include desktop-1920(
          (
            color: #bbbbbb
          )
        );
      }
    }

    textarea {
      @include desktop-1920(
        (
          padding: 15px
        )
      );

      @include phone(
        (
          padding: 15px
        )
      );
    }

    input[type='text'],
    input[type='number'],
    input[type='file'],
    input[type='tel'],
    input[type='date'],
    select {
      @include desktop-1920(
        (
          height: 56px
        )
      );

      @include phone(
        (
          height: 40px
        )
      );
    }

    input[type='checkbox'] {
      @include desktop-1920(
        (
          transform: scale(1.3, 1.3),
          vertical-align: middle,
          margin: 0 8px 2px 0
        )
      );

      @include phone(
        (
          margin: 0 5px 2px 0
        )
      );
    }
  }

  .general-form--notice {
    @include desktop-1920(
      (
        color: $red,
        padding: 5px 0 0 0,
        font-size: 14px
      )
    );

    @include phone(
      (
        padding: 5px 0 0 0,
        font-size: 14px
      )
    );
  }

  .general-form--actions {
    @include desktop-1920(
      (
        width: 100%
      )
    );

    @include phone(
      (
        padding: 0 5px
      )
    );
  }
}
