// Legacy - delete
// Mixin for handling Edge specific styles
@mixin is-edge() {
  @supports (-ms-ime-align: auto) {
    @content;
  }
}
// Mixin for handling IE 11 specific styles
@mixin is-IE() {
  @media screen and (-ms-high-contrast: active),
    screen and (-ms-high-contrast: none) {
    @content;
  }
}
// Mixin for handling specific styles for IE11 and Edge
@mixin for-IE-and-Edge() {
  @supports (-ms-ime-align: auto) {
    @content;
  }
  @media screen and (-ms-high-contrast: active),
    screen and (-ms-high-contrast: none) {
    @content;
  }
}
// Mixin for handling Firefox specific styles
@mixin is-FF() {
  @supports (-moz-appearance: none) {
    @content;
  }
}
