// Legacy - delete

@import '../mixins/responsive.mixin';

.nm-rep-card {
  @include desktop-1920(
    (
      position: relative,
      z-index: 1,
      display: flex,
      align-items: flex-start,
      flex-wrap: wrap,
      padding: 50px 0,
      border-bottom: 1px solid #e6e6e6,
      color: #4e4e4e,
      max-width: 1440px,
      margin: 0 auto
    )
  );

  @include phone(
    (
      flex-direction: column,
      padding: 40px 0,
      border-width: 1px,
      max-width: 280px
    )
  );

  &.is-expanded {
    @include desktop-1920(
      (
        z-index: 2
      )
    );
  }

  &:last-child {
    @include desktop-1920(
      (
        border-bottom-width: 0
      )
    );
  }

  .nm-rep-card--content {
    padding: 20px;
  }

  .nm-rep-card--info {
    @include desktop-1920(
      (
        flex: 1,
        padding: 40px 60px 0 340px
      )
    );

    @include phone(
      (
        flex: 0 1 auto,
        padding: 0
      )
    );

    .nm-rep-card--primary-info {
      @include phone(
        (
          display: flex,
          justify-content: flex-start,
          align-items: stretch,
          flex-direction: column,
          margin: 0 0 12px 0
        )
      );

      picture.nm-ensemble-card--photo {
        @include desktop(
          (
            width: 423px,
            background-color: $grey-lightest,
            float: left,
            margin: -40px 0 0 -481px
          )
        );

        @include phone(
          (
            margin: 0 0 18px 0,
            height: 168px,
            width: 252px,
            float: none
          )
        );

        > img {
          @include desktop(
            (
              transform: translate(-50%, 0%),
              top: 0%,
              left: 50%,
              height: auto,
              min-width: 100%,
              min-height: 100%
            )
          );
        }
      }

      picture.nm-rep-card--photo {
        @include desktop-1920(
          (
            height: 282px,
            width: 282px,
            background-color: $grey-lightest,
            float: left,
            margin: -40px 0 0 -340px
          )
        );

        @include phone(
          (
            margin: 0 0 18px 0,
            height: 250px,
            width: 250px,
            float: none
          )
        );
        display: inline-block;

        overflow: hidden;

        > img {
          @include desktop-1920(
            (
              transform: translate(-50%, 0%),
              top: 0%,
              left: 50%,
              height: auto,
              min-width: 100%,
              min-height: 100%
            )
          );

          position: relative;
          width: 100%;
          vertical-align: middle;
        }
      }

      .nm-rep-card--title {
        @include desktop-1920(
          (
            margin: 0 0 14px 0
          )
        );

        @include phone(
          (
            padding: 0,
            margin: 0
          )
        );

        .nm-rep-card--name {
          @include light-font;
          @include desktop-1920(
            (
              font-size: 28px,
              letter-spacing: 3px,
              text-transform: none,
              margin: 0
            )
          );

          @include phone(
            (
              line-height: 32px,
              width: 100%,
              font-size: 28px,
              color: #4e4e4e,
              letter-spacing: 3px
            )
          ) {
            @include regular-font;
          }
        }

        .nm-rep-card--role {
          @include light-font;
          @include desktop-1920(
            (
              margin: 0,
              font-size: 17px,
              text-transform: none,
              letter-spacing: 1px,
              line-height: 30px
            )
          );

          @include phone(
            (
              margin: 0,
              font-size: 17px,
              color: #4e4e4e,
              letter-spacing: 1px,
              line-height: 30px
            )
          ) {
            @include regular-font;
          }
        }

        .nm-rep-card--ensemble {
          @include regular-font;
          @include desktop-1920(
            (
              margin: 0,
              font-size: 17px,
              text-transform: none,
              letter-spacing: 1px,
              line-height: 30px
            )
          );

          @include phone(
            (
              margin: 0,
              font-size: 17px,
              color: #4e4e4e,
              letter-spacing: 1px,
              line-height: 30px
            )
          );
        }
      }
    }

    .nm-rep-card--additional-info {
      @include desktop-1920(
        (
          margin: 0 0 20px 0
        )
      );

      @include phone(
        (
          padding: 0,
          margin: 0 0 20px 0
        )
      );

      ul.nm-rep-card--designations {
        @include desktop-1920(
          (
            list-style: none,
            padding: 0,
            margin: 0 0 10px 0
          )
        );

        @include phone(
          (
            height: auto,
            margin: 0
          )
        );

        > li {
          @include regular-font;
          @include desktop-1920(
            (
              position: relative,
              display: inline,
              font-size: 13px,
              margin-right: 5px,
              line-height: 30px
            )
          );

          @include phone(
            (
              font-size: 13px,
              color: #4e4e4e,
              letter-spacing: 0.93px,
              line-height: 30px
            )
          );

          &:after {
            @include desktop-1920(
              (
                content: ',',
                margin: 0 3px 0 2px
              )
            );

            @include phone(
              (
                margin: 0 5px 0 2px
              )
            );
          }

          &:last-child:after {
            display: none;
          }
        }
      }

      > a {
        @include regular-font;
        @include desktop-1920(
          (
            font-size: 14px,
            letter-spacing: 1px,
            display: block
          )
        );

        @include phone(
          (
            font-size: 12px,
            letter-spacing: 1px,
            margin: 0 0 10px 0
          )
        );
      }
    }
  }

  .nm-rep-card--info.is-ensemble {
    @include desktop(
      (
        flex: 1,
        padding: 40px 60px 0 481px
      )
    );

    @include phone(
      (
        flex: 0 1 auto,
        padding: 0
      )
    );
  }

  .nm-rep-card--contact {
    @include desktop-1920(
      (
        width: 280px,
        padding: 40px 0 0 0
      )
    );

    @include phone(
      (
        width: 100%,
        padding: 0
      )
    );

    address {
      @include desktop-1920(
        (
          white-space: pre-wrap,
          font-size: 16px,
          letter-spacing: 1px,
          line-height: 30px,
          font-style: normal,
          display: block
        )
      ) {
        @include light-font;
      }
    }

    .nm-rep-card--address {
      @include phone(
        (
          font-size: 16px,
          color: #4e4e4e,
          letter-spacing: 1px,
          line-height: 30px
        )
      ) {
        @include regular-font;
      }
    }

    > a.nm-ensemble-card--phone {
      @include light-font;
      @include desktop(
        (
          white-space: pre-wrap,
          font-size: 16px,
          letter-spacing: 1px,
          line-height: 30px,
          margin: 15px 0 15px 0,
          display: block
        )
      );

      @include phone(
        (
          font-size: 14px,
          line-height: 18px,
          letter-spacing: 1px,
          margin: 15px 0 19px 0
        )
      );
    }

    > a.nm-rep-card--phone {
      @include light-font;
      @include desktop-1920(
        (
          white-space: pre-wrap,
          font-size: 16px,
          letter-spacing: 1px,
          line-height: 30px,
          margin: 0,
          display: block
        )
      );

      @include phone(
        (
          font-size: 14px,
          line-height: 18px,
          letter-spacing: 1px,
          margin: 15px 0 0 0
        )
      );
    }

    ul.nm-rep-card--contact-links {
      @include desktop-1920(
        (
          list-style: none,
          padding: 0,
          margin: 23px 0 29px 0
        )
      );

      @include phone(
        (
          margin: 17px 0 25px 0
        )
      );

      > li {
        @include desktop-1920(
          (
            display: inline-block
          )
        );

        @include phone(
          (
            display: block
          )
        );

        > a {
          @include regular-font;
          @include desktop-1920(
            (
              font-size: 14px,
              letter-spacing: 1px
            )
          );

          @include phone(
            (
              font-size: 12px,
              letter-spacing: 1px,
              line-height: 25px
            )
          );
        }

        &:after {
          @include desktop-1920(
            (
              content: '',
              border-radius: 3px,
              height: 3px,
              width: 3px,
              background-color: $silver,
              margin: 0 7px,
              display: inline-block,
              vertical-align: middle
            )
          );

          @include phone(
            (
              display: none
            )
          );
        }

        &:last-child:after {
          @include desktop-1920(
            (
              display: none
            )
          );
        }
      }
    }

    > button {
      @include desktop-1920(
        (
          width: 100%,
          padding-top: 0
        )
      );

      @include phone(
        (
          padding: 0,
          width: 100%
        )
      );
    }
  }

  .nm-rep-card--dropdown {
    @include desktop-1920(
      (
        display: flex,
        align-items: stretch,
        justify-content: flex-start,
        flex-direction: row,
        width: 100%,
        background-color: $white,
        padding: 70px 0 0 0,
        margin: 0 0 35px 0
      )
    );

    @include phone(
      (
        flex-direction: column,
        padding: 30px 0 0 0
      )
    );

    .nm-rep-card--dropdown-info {
      @include desktop-1920(
        (
          width: 50%,
          padding: 0 55px 0 0,
          border-right: 1px solid #f5a623
        )
      );

      @include phone(
        (
          width: 100%,
          border-right: none,
          padding: 0
        )
      );

      h3 {
        @include light-font;
        @include desktop-1920(
          (
            font-size: 25px,
            color: #2a2a2a,
            letter-spacing: 3.75px,
            line-height: 40px,
            margin: 0 0 5px 0,
            padding: 0
          )
        );

        @include phone(
          (
            font-size: 18px,
            color: $grey-dark,
            letter-spacing: 3px,
            line-height: 26px,
            text-transform: uppercase,
            width: 100%,
            margin: 0 0 10px 0
          )
        );
      }

      p {
        @include light-font;
        @include desktop-1920(
          (
            font-size: 20px,
            color: #2a2a2a,
            letter-spacing: 1px,
            line-height: 31px,
            margin: 0 0 50px 0
          )
        );

        @include phone(
          (
            font-size: 13px,
            color: #2a2a2a,
            letter-spacing: 1px,
            line-height: 20px,
            margin: 0 0 30px 0
          )
        );
      }

      picture {
        @include desktop-1920(
          (
            height: 282px,
            width: 534px,
            border: 1px solid #979797
          )
        );

        @include phone(
          (
            height: 414.5px,
            width: 250px,
            margin: 0 0 35px 0,
            border-width: 1px
          )
        );
      }
    }

    .nm-rep-card--dropdown-service {
      @include desktop-1920(
        (
          width: 50%,
          padding: 0 0 0 50px
        )
      );

      @include phone(
        (
          width: 100%,
          padding: 0
        )
      );
    }
  }
}
