// Legacy - delete
$font-family: 'Guardian Sans', 'Helvetica Neue', Arial, sans-serif;

@mixin light-font {
  font-family: $font-family;
  font-weight: 200;
}

@mixin regular-font {
  font-family: $font-family;
  font-weight: 300;
}

@mixin medium-font {
  font-family: $font-family;
  font-weight: 400;
}

@mixin semibold-font {
  font-family: $font-family;
  font-weight: 500;
}

@mixin bold-font {
  font-family: $font-family;
  font-weight: 600;
}
