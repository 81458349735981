// Legacy - delete
h2 {
  @include light-font;
  @include desktop-1920(
    (
      letter-spacing: 7px,
      line-height: 44px,
      font-size: 36px,
      margin: 0
    )
  );

  @include phone(
    (
      font-size: 22px,
      letter-spacing: 3.89px,
      line-height: 44px
    )
  );

  &.is-uppercase {
    @include desktop-1920(
      (
        text-transform: uppercase
      )
    );
  }
}

sup {
  top: -0.75em;
  margin-right: 0.1em;
  margin-left: 0.1em;
  // new: .nmx-sup
  font-size: 0.5em;

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }
}

a {
  color: $nm-blue;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    color: $yellow-darker;
  }
}
