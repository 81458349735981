// Legacy - delete
/* Thin */
@font-face {
  font-family: 'Guardian Sans';
  font-weight: 100;
  src: local('GuardianSans-Thin'),
    url('../../fonts//guardian/GuardianSans-Thin.woff2') format('woff2'),
    url('../../fonts/guardian/GuardianSans-Thin.woff') format('woff');
}

/* Light */
@font-face {
  font-family: 'Guardian Sans';
  font-weight: 200;
  src: local('GuardianSans-Light'),
    url('../../fonts/guardian/GuardianSans-Light.woff2') format('woff2'),
    url('../../fonts/guardian/GuardianSans-Light.woff') format('woff');
}

/* Regular */
// for some reason, this font couldn't be found locally, probably none of the legacy fonts truly work.
// But this one was needed and 404'dwhen the font was loaded with the old method
// Now I point at plan.northwesternmutual.com like Trevor had done
@font-face {
  font-family: 'Guardian Sans';
  font-weight: 300;
  src: url('https://plan.northwesternmutual.com/static/assets/public/fonts/GuardianSans-Regular.woff2')
      format('woff2'),
    url('https://plan.northwesternmutual.com/static/assets/public/fonts/GuardianSans-Regular.woff')
      format('woff');
}

/* Medium */
@font-face {
  font-family: 'Guardian Sans';
  font-weight: 400;
  src: local('GuardianSans-Medium'),
    url('../../fonts/guardian/GuardianSans-Medium.woff2') format('woff2'),
    url('../../fonts/guardian/GuardianSans-Medium.woff') format('woff');
}

/* SemiBold */
@font-face {
  font-family: 'Guardian Sans';
  font-weight: 500;
  src: local('GuardianSans-SemiBold'),
    url('../../fonts/guardian/GuardianSans-SemiBold.woff2') format('woff2'),
    url('../../fonts/guardian/GuardianSans-SemiBold.woff') format('woff');
}

// TODO: determine where and how often Tandelle is being used - might be worth living in specific app (not template)
@font-face {
  font-family: 'tandelle';
  font-weight: 400;
  src: local('Tandelle-Regular'),
    url('../../fonts/tandelle/Tandelle-Regular.woff2') format('woff2'),
    url('../../fonts/tandelle/Tandelle-Regular.woff') format('woff');
}
