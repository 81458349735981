// Legacy - delete
.nmx-sup {
  top: -0.75em;
  margin-right: 0.1em;
  margin-left: 0.1em;
  font-size: 0.5em;

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }
}
