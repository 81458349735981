%nmx-button {
  @include no-tap;
  @include semibold-font;
  @include desktop-1920(
    (
      border-width: 0,
      cursor: pointer,
      text-align: center,
      text-transform: uppercase,
      transition: background-color $micro,
      border-radius: 2px,
      padding: 3px 73px 0 73px,
      height: 72px,
      letter-spacing: 3px,
      font-size: 18px,
      appearance: none
    )
  );

  @include phone(
    (
      height: 50px,
      font-size: 12px,
      letter-spacing: 3px,
      width: 100%
    )
  );
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  background-color: $nm-yellow;
  color: $black;

  text-decoration: none;

  &:hover {
    background-color: $nm-yellow-hover;
    color: $black;
  }

  &[disabled],
  &[disabled]:hover {
    background-color: $grey-lightest;
    color: $grey-darkest;
    cursor: not-allowed;
  }

  img,
  svg.img {
    @include desktop-1920(
      (
        position: relative,
        top: -2px,
        height: 11px,
        width: 8px,
        vertical-align: middle,
        margin: 0 0 0 5px
      )
    );

    @include phone(
      (
        height: 10px,
        width: 6px,
        margin: 0 0 0 5px
      )
    );
  }
}

// Legacy - delete
// Modifiers
%nmx-button--secondary {
  background-color: $nm-blue;
  color: $white;

  &:hover {
    background-color: #3586ca;
  }
}

%nmx-button--small {
  @include desktop-1920(
    (
      height: 54px,
      font-size: 12px,
      padding-left: 52px,
      padding-right: 52px,
      letter-spacing: 3px,
      line-height: 58px
    )
  );

  @include phone(
    (
      height: 48px,
      font-size: 10px,
      line-height: 14px,
      padding: 2px 30px 0 30px,
      letter-spacing: 3px
    )
  );
}
